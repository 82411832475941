.template-choice {
    padding: 20px;

    .structure {
        display: flex;
        gap: 20px;

        .list {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .option {
                padding: 10px;
                border: 1px solid rgb(63, 125, 232);
                border-radius: 10px;
                color: rgb(63, 125, 232);
                font-weight: 600;
                cursor: pointer;
                transition: 0.1s;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .language {
                    color: gray;
                }

                &:hover {
                    background-color: rgb(63, 125, 232, 0.05);
                    transition: 0.1s;
                }

                &[data-active="true"] {
                    background-color: rgb(63, 125, 232);
                    color: white;

                    .language {
                        color: white;
                    }
                }
            }

            max-height: 60vh;
            overflow-y: auto;
        }

        .informations {
            width: 25%;

            .variable-area {
                .tips-area {
                    position: relative;

                    .tips {
                        position: absolute;
                        bottom: 0;
                        left: 5px;
                        z-index: 100;
                        display: none;
                        gap: 5px;
                        flex-wrap: wrap;
                        background-color: white;
                        padding: 5px;
                        border: 1px solid #e7edf4;
                        border-bottom: 0;
                        border-radius: 10px 10px 0 0;
                        max-width: calc(100% - 10px);
                        width: fit-content;
                    }
                }

                &:hover {
                    .tips-area {
                        .tips {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
