.pipeline-choice {
    padding: 20px;

    .pipeline-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .option {
            padding: 10px;
            border: 1px solid rgb(63, 125, 232);
            border-radius: 10px;
            color: rgb(63, 125, 232);
            font-weight: 600;
            cursor: pointer;
            transition: 0.1s;

            &:hover {
                background-color: rgb(63, 125, 232, 0.05);
                transition: 0.1s;
            }

            &[data-active="true"] {
                background-color: rgb(63, 125, 232);
                color: white;
            }
        }
    }
}
