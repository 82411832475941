.btn2 {
    border: 1px solid #3f7de8;
    color: #3f7de8;
    cursor: pointer;

    bds-typo {
        color: #3f7de8;
    }

    &:hover {
        background: rgb(232, 242, 255);
    }
}
