.crm-active-messages-container {
    margin-top: 20px;

    .items {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;

        table {
            width: 100%;

            tbody {
                tr {
                    td {
                        border-top: 1px solid #ccc;
                        padding: 5px;
                    }
                }
            }
        }

        .card-resource {
            .none {
                visibility: hidden;
            }

            &:hover {
                .none {
                    visibility: visible;
                }
            }
        }
    }
}
