.action-icons-container {
    position: relative;

    .action-icons {
        display: none;
        position: absolute;
        right: 60px;
        top: 35px;
    }

    &:hover {
        .action-icons {
            display: block;
        }
    }
}
