.marketplace-step-options {
    gap: 20px;
    align-items: center;
    justify-content: center;

    .option-card {
        width: calc(50% - 10px);
        padding: 30px 0;
        transition: 0.3s;
        border: 2px solid transparent;

        &:hover {
            background: rgb(243, 243, 243);
            transition: 0.3s;
        }

        &[selected="true"] {
            border: 2px solid black;
        }
    }
}
