.select-phone-number.sc-bds-input-phone-number {
    min-width: 0px;
}

.input--state-primary.sc-bds-input-phone-number {
    border: 0;
}
.element_input.sc-bds-input-phone-number {
    width: fit-content;
    // width: 30px;
    height: 40px;
    flex: 0;
}

.sc-bds-input-phone-number {
    // background: red !important;
    border: 0;

    .input__container {
        display: none;
    }
}
