.card {
    padding: 20px;
    position: relative;

    bds-typo {
        margin: 0;
        padding: 0;
    }

    .details {
        display: none;
        transition: 0.3s;
    }

    .buttons-area {
        margin-left: 20px;
    }

    &:hover {
        .details {
            display: block;
            transition: 0.3s;
        }
    }

    .link {
        text-decoration: none;
    }
}
