.code-editor-area {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    background-color: #1e1e1e;

    .top {
        border-bottom: 0.5px solid #455258;

        .tap {
            color: #455258;
            border: 1px solid #455258;
            border-bottom: 0px;
            width: fit-content;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            gap: 5px;

            svg {
                width: 25px;
            }

            p {
                font-size: 14px;
                margin: 0;
            }
        }
    }
}
