.p-result {
    font-weight: bold;

    &[result="success"] {
        color: green;
    }
    &[result="failed"] {
        color: red;
    }
}

.icon-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.link {
    color: gray;
    cursor: pointer;
}

.object-view-base {
    background-color: #222222;
    padding: 10px 0;
    border-radius: 10px;
}
